<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="¡Ups!"
    />

    <base-subheading
      space="8"
      title="Parece que esta página no existe"
    />

    <base-img
      :src="require('@/assets/cantdelimar-logo.png')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    />
    <div class="text-center aling-center d-flex justify-center justify-md-none flex-wrap ">
      <template v-for="(r, i) in rutas">
          <v-btn
          color="primary"
          x-large
          outlined
          :key="i"
          class="white--text pa-1 pa-md-0 mt-1 ml-1"
          :to="{ name: r.ref }"
          v-text="r.label"
        />
        <v-responsive
          v-if="i < rutas.length - 1"
          :key="`divider-${i}`"
          class="mx-2 shrink hidden-sm-and-down"
          max-height="24"
        >
        </v-responsive>
      </template>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',
    data: () => ({
      rutas: [
        {
          label: 'Explora nuestros Productos!',
          ref: 'Productos',
        },
        {
          label: 'Volver al inicio!',
          ref: 'Inicio',
        },
        {
          label: 'Contáctanos',
          ref: 'Contacto',
        },
      ],
    }),
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
